import { useDispatch } from "react-redux";
import { deleteexhibitor } from 'src/redux/userSlice';
import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Button, Container } from '@mui/material';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ExbDelm = (props) => {
  const { id } = props

  const dispatch = useDispatch();

  const handleClose = () => {
    props.callback()
  };

  const handleDelete = () => {
    dispatch(deleteexhibitor({ id, toast }))
  };

  return (
    <div>
      <BootstrapDialog
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.openformmodal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {'Delete exhibitor'}
        </DialogTitle>
        <IconButton

          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Container>
          <DialogContent dividers>
            <Typography variant="h6" gutterBottom>
              Are you sure you want to Delete exhibitor
            </Typography>

          </DialogContent>
        </Container>
        <DialogActions>
          <Button variant="contained" color="secondary" type="submit" onClick={handleDelete}>
            Yes
          </Button>
          <Button variant="contained" color="error" type="submit" onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}

export default ExbDelm