import React,  { useEffect } from 'react'
import { Grid, Container, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector } from "react-redux";
import { getSettings } from 'src/redux/userSlice';

const HeadCount = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getSettings());
    },[]);
  
    const  {settings}  = useSelector((state) => ({ ...state.users}));
  
    const List = settings?.filter(item => item.type == 'hall');
  return (
    <>
    <Container maxWidth="xl">
    <Typography variant="h4" sx={{ mb: 5 }}>
   Head Count
    </Typography>
    <Grid container spacing={3}>
      { List?.map((cert,index) => (<Grid item xs={12} sm={6} md={3}>
      <div className="card" style={{ 
borderRadius : '8px',
width: '215px',
height: '215px',
backgroundColor: '#3d3b3d',
borderRadius: '10px',
display: 'flex',
flexDirection: 'column',
alignItems: 'center',
justifyContent: 'center',
position: 'relative',
overflow: 'hidden'
}}
onClick={() => navigate(`/FwhScan/${cert.dbname}`)}>
  <h2 className="card-title" style={{fontSize: '1.5rem',color:'white'}}>{cert.name ? cert.name : 'NA'}</h2>
</div> 
      </Grid>
      ))}
     </Grid>
   
    </Container>
</>
  )
}

export default HeadCount