import React, { useState } from 'react';
import useScanDetection from 'use-scan-detection';
export const ScanDetection = () => {
    const [value, setValue] = useState();
 
    useScanDetection({
        onComplete: setValue,
        minLength: 2 // EAN13
    });
    // console.log(value)
return value
}
