import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Webcam from "react-webcam";
import jsQR from "jsqr";
import { getUsers, } from '../redux/userSlice';
import SelfcheckinModal from '../Admin/modal/SelfcheckinModal';

const Selftest = () => {
    const dispatch = useDispatch();
    const [openformmodal, setOpenFormModal] = useState(false);
    const handleFormOpen = () => {
        setOpenFormModal(true);
      };
      const callback = ()=>{
        console.log('modal close is calling')
        setOpenFormModal(false)
       }
  const webcamRef = useRef(null);
  const [scannedValue, setScannedValue] = useState(null);
  const [userName, setUserName] = useState("");
  useEffect(() => {
    dispatch(getUsers());
  },[]);
  const  {users,error,loading}  = useSelector((state) => ({ ...state.users}));



  useEffect(() => {
    const interval = setInterval(() => {
      capture();
    }, 1000); // Check every second

    return () => clearInterval(interval);
  }, []);

  const capture = async () => {
    if (!webcamRef.current) return;

    const imageSrc = webcamRef.current.getScreenshot();
    if (!imageSrc) return;

    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      const code = jsQR(imageData.data, canvas.width, canvas.height);
      if (code && code.data) {
        handleDecode(code.data);
      }
    };
  };

  const handleDecode = async (value) => {
    if (scannedValue) return;

    if(users?.length){

        console.log('users',users,'value',value)
        const {full_name} = users.find(user => user.reg_num == value)
        setUserName(full_name);
        setScannedValue(value);
        if (full_name) {
            handleFormOpen()
        //   generatePDFAndPrint(value, full_name);
        }
    }

  };


  return (
    <div>

<div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        videoConstraints={{ facingMode: 'user' }}
        style={{ width: '100%', height: '100%' }}
      />
      {/* Overlay with corner indicators */}
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <div style={{
          position: 'relative',
          width: '300px', // Adjust based on your needs
          height: '300px', // Adjust based on your needs
        }}>
          {/* Top-Left corner */}
          <div style={{
            position: 'absolute',
            top: '-10px',
            left: '-10px',
            width: '30px',
            height: '30px',
            borderTop: '3px solid red',
            borderLeft: '3px solid red',
          }}></div>
          {/* Top-Right corner */}
          <div style={{
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            width: '30px',
            height: '30px',
            borderTop: '3px solid red',
            borderRight: '3px solid red',
          }}></div>
          {/* Bottom-Left corner */}
          <div style={{
            position: 'absolute',
            bottom: '-10px',
            left: '-10px',
            width: '30px',
            height: '30px',
            borderBottom: '3px solid red',
            borderLeft: '3px solid red',
          }}></div>
          {/* Bottom-Right corner */}
          <div style={{
            position: 'absolute',
            bottom: '-10px',
            right: '-10px',
            width: '30px',
            height: '30px',
            borderBottom: '3px solid red',
            borderRight: '3px solid red',
          }}></div>
        </div>
      </div>
    </div>
      {scannedValue && (
        <div
          id="print-content"
          style={{
            textAlign: "center",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            visibility: "hidden",
          }}
        >
          <p>{userName}</p>
        </div>
      )}
      <div>

      </div>
      <SelfcheckinModal
      scannedValue={scannedValue}
       userName={userName}
 openformmodal={openformmodal}
 callback={callback}
 />
    </div>
  );
};

export default Selftest;
