import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';

// @mui
import { Card, Grid, CardContent, Button } from '@mui/material';
// utils
import Iconify from '../../../components/iconify';
import ExbDelm from 'src/Admin/modal/ExbDelm';
// ----------------------------------------------------------------------

ExhibitorPostCard.propTypes = {
  volunt: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function ExhibitorPostCard({ volunt, index,usersDetailesEdit }) {
  console.log('volunt',volunt)
  const { email, password,id  } = volunt;
  const [isEyeClicked, setEyeClicked] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
      const [openformmodal, setOpenFormModal] = useState(false);

  const cardRef = useRef();

  const handleFormOpen = () => {
      setOpenFormModal(true);
    };
  const handleEyeClick = () => {
    const rect = cardRef.current.getBoundingClientRect();
    setPosition({ x: rect.left, y: rect.bottom });
    setEyeClicked((prev) => !prev);
  };
  const handleEdit = (id) => {
    usersDetailesEdit(id)
  };


  const callback = ()=>{
    setOpenFormModal(false)
   }
  
  return (
    <>
      <Grid item xs={4}>
        <Card ref={cardRef} sx={{ position: 'relative' }}>
<div>
<Button onClick={() => handleEdit(id)} startIcon={<Iconify icon="uil:edit"/>} />
          <Button onClick={() => handleFormOpen()} startIcon={<Iconify icon="gridicons:trash"/>} />
</div>
         

          <CardContent sx={{ pt: 4 }}>
            <h3>{email}</h3>

            <div style={{display:"flex",gap:'4'}}>
              <div>
              <Button onClick={handleEyeClick} startIcon={<Iconify icon="fa:eye" />} />
              </div>
              {isEyeClicked && (
                <div>
                  {password}
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </Grid>
      <ExbDelm
      id={id}
openformmodal={openformmodal}
callback={callback}
file/>
    </>
  );
}
