import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import React, { useState, useEffect } from 'react';
import { SiMicrosoftexcel } from 'react-icons/si';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton 
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { fetchExbDataById  } from '../redux/userSlice';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import BackdropLoading from './BackdropLoading';
import { ScanDetection } from './ScanDetection';



import { SaveAlt } from '@mui/icons-material';
import * as XLSX from 'xlsx';

const downloadExcel = (data) => {
  // Create a worksheet from the data
  const worksheet = XLSX.utils.json_to_sheet(data);
  // Create a new workbook
  const workbook = XLSX.utils.book_new();
  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendees');
  // Generate the Excel file and trigger download
  XLSX.writeFile(workbook, 'Attendees.xlsx');
};

const TABLE_HEAD = [
  { id: 'name', label: 'Reg.No', alignRight: false },
  { id: 'company', label: 'Full Name', alignRight: false },
  { id: 'role', label: 'Category', alignRight: false },
  { id: 'isVerified', label: 'Email', alignRight: false },
  { id: 'isVerified', label: 'Mobile', alignRight: false },
  { id: 'print', label: 'Comment', alignRight: false },
];
export default function ExbhitorDashboard() {
  const regNumFromScanDetection = ScanDetection()
  const qrCodevalue = regNumFromScanDetection ? regNumFromScanDetection.replaceAll('Shift', '') : null
  const dispatch = useDispatch();
  const  {exbdata,error,loading}  = useSelector((state) => ({ ...state.users}));
  

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [exbid, setExbId] = useState("");



useEffect(() => {
  const jwtToken = localStorage.getItem('auth');
  const base64Url = jwtToken.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const decodedPayload = JSON.parse(atob(base64));
  dispatch(fetchExbDataById(decodedPayload.UserInfo.id));
},[]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = exbdata?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  
  useEffect(()=>{
    setPage(0);
    setFilterName(qrCodevalue);
},[qrCodevalue])

 



  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - exbdata?.length) : 0;
  

if(exbdata?.length){
  
  var filteredUsers = applySortFilter(exbdata, getComparator(order, orderBy), filterName);
  
function descendingComparator(a, b, orderBy) {
    
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  console.log('array',array)
 
  if (query) {
     return filter(array, (_user) =>{
        return(
           _user.reg_num.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
           _user.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
           _user.mobile.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
           _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1 
        )
      })
  }
  return stabilizedThis.map((el) => el[0]);
}

}

  const isNotFound = !filteredUsers?.length && !!filterName;



  return (
    <>

      <Helmet>
      <title> Badging.com </title>
      </Helmet>
      <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Attendees
        </Typography>
        <SiMicrosoftexcel
          onClick={() => downloadExcel(exbdata)}
          style={{ cursor: 'pointer', fontSize: 30, color: 'green' }}
        />
      </Stack>
      <Card>
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={exbdata?.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {exbdata?.length ? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, inx) => {
                  const { reg_num, full_name, category, mobile, email, comments, note, reference } = row;
                  const selectedUser = selected.indexOf(full_name) !== -1;
                  return (
                    <TableRow hover key={inx} tabIndex={-1} role="checkbox" selected={selectedUser}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, full_name)} />
                      </TableCell>
                      <TableCell align="left">{reg_num}</TableCell>
                      <TableCell align="left">{full_name}</TableCell>
                      <TableCell align="left">{category}</TableCell>
                      <TableCell align="left">{email}</TableCell>
                      <TableCell align="left">{mobile}</TableCell>
                      <TableCell align="left">{comments}</TableCell>
                    </TableRow>
                  );
                }) : ''}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={exbdata?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
      <div>
      </div>
      {/* <BackdropLoading loading={loading}/> */}
    </>
  );
}