import React from 'react'
import Exhibitor from '../Exhibitor'

const TabPanel4 = () => {

  return (
    <Exhibitor/>
  )
}

export default TabPanel4