import React, { useState } from 'react';
import { Container, Card, Button, Stack, Box, CardHeader } from '@mui/material';
import Iconify from '../components/iconify';
import UploadModal from './modal/UploadModal';



export const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [openformmodal, setOpenFormModal] = useState(false);

  const handleFormOpen = () => {
    setOpenFormModal(true);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const callback = () => {
    setOpenFormModal(false)
  }
  return (
    <>
      <div>
        <UploadModal
          openformmodal={openformmodal}
          callback={callback}
          file={file}
        />
      </div>
      <Container>
        <Box sx={{ pt: 2 }}>
          <Card>
            <CardHeader title={'Upload CSV'} />
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} style={
              { padding: '20px' }
            }>
              <input type="file" onChange={handleFileChange} />
              <Button variant="contained" startIcon={<Iconify icon="ri:upload-cloud-fill" />} onClick={() => handleFormOpen()}>
                Upload
              </Button>
            </Stack>
          </Card>
        </Box>
      </Container>

    </>


  )
}

