import * as React from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Iconify from '../components/iconify';
import SettingModal from './SettingModal';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Modal,
  Grid,
  Dialog 
} from '@mui/material';
import { useState,useEffect  } from 'react';
import { getSettings } from 'src/redux/userSlice';
import { useDispatch, useSelector } from "react-redux";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}));

const lightTheme = createTheme({ palette: { mode: 'light' } });

const data = [
    {
        name : 'Certificate',
        icon : 'mdi:certificate',
        type:'certificate'
},
    {name : 'Food',
icon : 'mdi:food',
type:'lunch'},
    {name : 'Work Shop',
icon : 'grommet-icons:workshop',
type:'work_shop'
},
    {name : 'Head Count',
icon :'fa6-solid:users',
type:'hall'
},

]


export default function Settings() {
    const dispatch = useDispatch();
    const [openformmodal, setOpenFormModal] = useState(false);
    const[type,setType] = useState('')
    const[name,setName] = useState('')

 

    const handleFormOpen = (type,name) => {
        setOpenFormModal(true);
        setType(type)
        setName(name)
      };

      const callback = ()=>{
        setOpenFormModal(false)
       }
  return (
    <>
    <div>
    </div>
    <Grid container spacing={2}>
      {[lightTheme].map((theme, index) => (
        <Grid item xs={3} key={index}>
          <ThemeProvider theme={theme}>
            <Box
              sx={{display: 'grid', gap: 2,}}
            >
              {data.map((data,index) => (
                <>
       <Button   key={index} variant="contained" onClick={() => handleFormOpen(data.type,data.name)}   startIcon={<Iconify icon={`${data.icon}`} />}>
       {`${data.name}`}
          </Button>
                </>
              ))}
            </Box>
          </ThemeProvider>
        </Grid>
      ))}
    </Grid>
    <div>

  

    
    </div>
<div>
<SettingModal 
openformmodal={openformmodal}
callback={callback}
type={type}
name={name}
/>
</div>
</>
  );
}
