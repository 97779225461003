import { createSlice, createAsyncThunk} from '@reduxjs/toolkit'
  import axios from "axios";
  import usersapis from 'src/apis/usersapis';



export const getUsers = createAsyncThunk('posts/fetchPosts', async () => {
  
  try {
    const response = await usersapis.get('users/')
    return response.data
    // toast.success("Tour Added Successfully");
    // navigate("/");
    // return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})


// Async thunk to save delegate data
export const saveDelegateData = createAsyncThunk('users/saveDelegateData', async (data) => {
  const response = await usersapis.post('users/savedelegatedata', data);
  return response.data;
});

export const getfont = createAsyncThunk('posts/getfont', async () => {
  
  try {
    const response = await usersapis.get('users/getfont')
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})
export const authuser = createAsyncThunk('posts/fetchauthusers', async () => {
  
  try {
    const response = await usersapis.get('users/authuser/')
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})
export const getCerSettings = createAsyncThunk('posts/getCerSettings', async () => {
  try {
    const response = await usersapis.get('users/getcersettings')
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})
export const getSettings = createAsyncThunk('posts/fetchSettings', async () => {
  
  try {
    const response = await usersapis.get('users/getsettings')
    console.log(response)
    return response.data
    // toast.success("Tour Added Successfully");
    // navigate("/");
    // return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})
export const userBadgePrinted = createAsyncThunk('posts/sample', async ({reg_num}) => {

  try {
    const response = await usersapis.put('users/userbadgeprinted', reg_num)

    return response.data
    // toast.success("Tour Added Successfully");
    // navigate("/");
    // return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})
export const cersettings = createAsyncThunk('update/cersettings', async ({top,right,toast}) => {

  try {
    const response = await usersapis.put('users/certsettings', {top,right})
    toast.success('Certificate Postion Added successfully!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})
export const updateFontSize = createAsyncThunk('update/updateFontSize', async ({fonts,toast}) => {
console.log('fonts',fonts)
  try {
    const response = await usersapis.put('users/updatefont', {fonts})
    toast.success('Font Size Updated successfully!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})
export const addUser = createAsyncThunk('posts/user', async ({data,toast}) => {
  console.log('---formData---',data)
  try {
    const response = await usersapis.post('users/adduser', data)
      toast.success('User added successfully!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    return response.data
   

  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})
export const addauthuser = createAsyncThunk('auth/addauthuser', async ({formData,toast}) => {
  try {
    const response = await usersapis.post('users/addauthuser', formData)
      toast.success('Volunteer added successfully!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    return response.data
   

  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})
////////////////////////////////////////////////////////////////////////////////////////////
export const addexhibitor = createAsyncThunk('auth/addexhibitor', async ({formData,toast}) => {
  try {
    const response = await usersapis.post('users/addexhibitor', formData)
      toast.success('Volunteer added successfully!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    return response.data
   

  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})

export const updateexhibitor = createAsyncThunk('user/updateexhibitor', async ({formData,toast}) => {
  try {
    const response = await usersapis.put('users/updateexhibitor', formData)
    if(response.data.result == 1){
      toast.success('Volunter Updated Sussesfully', {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      toast.error('somthing went wrong', {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})

export const deleteexhibitor = createAsyncThunk('user/deleteexhibitor', async ({id,toast}) => {

  console.log('id',id)
  try {
    const response = await usersapis.delete(`users/deleteexhibitor/${id}`)
    if(response.status == 200){
      toast.success(`deleted user sucssfully`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      toast.error(`trouble deleting user`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    return response.data
   


  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})


//////////////////////////////////////////////////////////////////////////////////////////////
export const updateUser = createAsyncThunk('user/update', async ({ reg_num, halltype, toast }, { rejectWithValue }) => {
  const data = { reg_num, halltype };
console.log
  try {
    const response = await usersapis.put('users/', data);
    const { result } = response.data;

    if (result === 1) {
      toast.success('Access Permitted', {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      
    } else if (['work_shop5','work_shop3','work_shop4', 'work_shop6', 'd4_lunch','d5_lunch'].includes(halltype)) {
      toast.success('Access Permitted', {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error('Access Denied! Already Scanned', {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
export const updateauthuser = createAsyncThunk('user/updateauthuser', async ({formData,toast}) => {
  try {
    const response = await usersapis.put('users/updateauthuser', formData)
    
    
    if(response.data.result == 1){
      toast.success('Volunter Updated Sussesfully', {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      toast.error('somthing went wrong', {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})
export const updateUserForm = createAsyncThunk('user/updateform', async ({data,toast}) => {
  console.log('updateUserForm api is calling')
  try {
    const response = await usersapis.put('users/updateform', data)
    console.log('response.data.message',response.data.message)
    if(response.data.result == 1){
      toast.success(`${response.data.message}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      toast.error(`${response.data.message}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    return response.data
   


  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})
export const updateSettings = createAsyncThunk('user/updatesettings', async ({formData,toast}) => {
  console.log('updateUserForm api is calling,',)
  try {
    const response = await usersapis.put('users/updatesettings', formData)
    console.log('response.data.message',response)
    if(response.status == 200){
      toast.success(`${response.data.message}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      toast.error(`${response.data.message}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    return response.data
   


  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})
export const deleteVolenteer = createAsyncThunk('user/deleteauthuser', async ({id,toast}) => {

  console.log('id',id)
  try {
    const response = await usersapis.delete(`users/deleteauthuser/${id}`)
    if(response.status == 200){
      toast.success(`deleted user sucssfully`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      toast.error(`trouble deleting user`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    return response.data
   


  } catch (err) {
    return rejectWithValue(err.response.data);
  }
})

// Create an async thunk for fetching `exbdata` by `exbid`
export const fetchExbDataById = createAsyncThunk(
  'exbdata/fetchExbDataById',
  async (exbid, thunkAPI) => {
    try {
      const response = await usersapis.get(`users/exbdata/${exbid}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);





const initialState = {
    users : [],
    totalAttendess : null,
    delegateCount : null,
    facultyCount : null,
    membersCount: null,
    accompanypersonCount: null,
    badgePrinted : null,
    kitbag : null,
    head_halla : null,
    head_hallb : null,
    head_hallc : null,
    head_halld : null,
    TotalcertificateCount : null,
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    exbdata: null,
}

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    changePrintstatus: (state, action) => {
      const {reg_num} = action.payload.reg_num
      const existingUser = state.users.find(
        (existingUser) => existingUser.reg_num == reg_num
      );
      if (existingUser) {
        existingUser.printed = '1';
      }
    },
    changeCertificatestatus: (state, action) => {
      const {reg_num,halltype} = action.payload
      const existingUser = state.users.find(
        (existingUser) => existingUser.reg_num == reg_num
      );
        const halltypefotTable = halltype.toLowerCase();
      if (existingUser) {
        existingUser[halltypefotTable] = '1';
      }
    },
  },
  extraReducers: {
    [getSettings.pending]: (state, action) => {

      // state.loading = true;
    },
    [getSettings.fulfilled]: (state, action) => {
      console.log('action',action)
      state.settings = action.payload
      // state.loading = true;
    },
    [getSettings.rejected]: (state, action) => {
     
      // state.loading = true;
    },
    ////////////////////////////
    [getfont.fulfilled]: (state, action) => {

      const {font} = action.payload[0]
      state.fontSize = font

    },
    [updateFontSize.fulfilled]: (state, action) => {

      const {
        arg: { fonts
        },
      } = action.meta;
      state.fontSize = fonts


    },
    ///////////////////////
    [getCerSettings.fulfilled]: (state, action) => {
console.log('cersettingsData',action.payload)
      state.cersettingsData = action.payload
    },
    [getUsers.pending]: (state, action) => {
     
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, action) => {

     



      let count = action.payload.filter(users => users.printed == 1)
      let Accompanyperson = action.payload.filter(users => users.category == 1 && users.printed == 1)
      let Delegate = action.payload.filter(users => users.category == 2 && users.printed == 1)
      let ExecutiveCouncil = action.payload.filter(users => users.category == 3 && users.printed == 1)
      let ExecutiveCouncilMember = action.payload.filter(users => users.category == 4 && users.printed == 1)
      let Exhibitor = action.payload.filter(users => users.category == 5 && users.printed == 1)

      let Faculty = action.payload.filter(users => users.category == 6 && users.printed == 1)
      let officeBearer = action.payload.filter(users => users.category == 7 && users.printed == 1)
      let organizingCommitte = action.payload.filter(users => users.category == 8 && users.printed == 1)


      let kitbag = action.payload.filter(users => users.kitbag == 1)
      let head_halla = action.payload.filter(users => users.head_halla == 1)
      let head_hallb = action.payload.filter(users => users.head_hallb == 1)
      let head_hallc = action.payload.filter(users => users.head_hallc == 1)
      let head_halld = action.payload.filter(users => users.head_halld == 1)
      let d1_lunch = action.payload.filter(users => users.d1_lunch == 1)
      let d2_lunch = action.payload.filter(users => users.d2_lunch == 1)
      let d3_lunch = action.payload.filter(users => users.d3_lunch == 1)
      let d4_lunch = action.payload.filter(users => users.d4_lunch == 1)
      let d5_lunch = action.payload.filter(users => users.d5_lunch == 1)
      let d1_dinner = action.payload.filter(users => users.d1_dinner == 1)
      let d2_dinner = action.payload.filter(users => users.d2_dinner == 1)
      let d3_dinner = action.payload.filter(users => users.d3_dinner == 1)
      let d4_dinner = action.payload.filter(users => users.d4_dinner == 1)
      let d5_dinner = action.payload.filter(users => users.d5_dinner == 1)
      let work_shop1 = action.payload.filter(users => users.work_shop1 == 1)
      let work_shop2 = action.payload.filter(users => users.work_shop2 == 1)
      let work_shop3 = action.payload.filter(users => users.work_shop3 == 1)
      let work_shop4 = action.payload.filter(users => users.work_shop4 == 1)
      let work_shop5 = action.payload.filter(users => users.work_shop5 == 1)
      let work_shop6 = action.payload.filter(users => users.work_shop6 == 1)
      let Certificate1 = action.payload.filter(users => users.certificate1 == 1)
      let Certificate2 = action.payload.filter(users => users.certificate2 == 1)
      let Certificate3 = action.payload.filter(users => users.certificate3 == 1)
      let Certificate4 = action.payload.filter(users => users.certificate4 == 1)
      let Certificate5 = action.payload.filter(users => users.certificate5 == 1)
      let Certificate6 = action.payload.filter(users => users.certificate6 == 1)
      let Certificate7 = action.payload.filter(users => users.certificate7 == 1)
      let Certificate8 = action.payload.filter(users => users.certificate8 == 1)
      let Certificate9 = action.payload.filter(users => users.certificate9 == 1)
      let Certificate10 = action.payload.filter(users => users.certificate10 == 1)

 
      state.loading = false;
      state.totalAttendess = action.payload.length
      state.users = action.payload;
      state.badgePrinted = count.length
      ////////////////////////////////
      state.delegateCount = Delegate.length
      state.facultyCount = Faculty.length
      state.officeBearerCount = officeBearer.length
      state.accompanypersonCount = Accompanyperson.length
      state.ExecutiveCouncilCount = ExecutiveCouncil.length
      state.ExecutiveCouncilMemberCount = ExecutiveCouncilMember.length
      state.ExhibitorCount = Exhibitor.length
      state.organizingCommitteCount = organizingCommitte.length

     /////////////////////////////////////////////////////////     
      state.kitbag = kitbag.length
      state.head_halla = head_halla.length
      state.head_hallb = head_hallb.length
      state.head_hallc = head_hallc.length
      state.head_halld = head_halld.length
      state.TotalcertificateCount = Certificate1.length + Certificate2.length + Certificate3.length + Certificate4.length + Certificate5.length + Certificate6.length + Certificate7.length + Certificate8.length + Certificate9.length + Certificate10.length
      state.day1Lunch = d1_lunch.length
      state.day2Lunch = d2_lunch.length
      state.day3Lunch = d3_lunch.length
      state.day4Lunch = d4_lunch.length
      state.day5Lunch = d5_lunch.length
      state.day1Dinner = d1_dinner.length
      state.day2Dinner = d2_dinner.length
      state.day3Dinner = d3_dinner.length
      state.day4Dinner = d4_dinner.length
      state.day5Dinner = d5_dinner.length
      state.workShop1 = work_shop1.length
      state.workShop2 = work_shop2.length
      state.workShop3 = work_shop3.length
      state.workShop4 = work_shop4.length
      state.workShop5 = work_shop5.length
      state.workShop6 = work_shop6.length
      state.Certificate1 = Certificate1.length
      state.Certificate2 = Certificate2.length
      state.Certificate3 = Certificate3.length
      state.Certificate4 = Certificate4.length
      state.Certificate5 = Certificate5.length
      state.Certificate6 = Certificate6.length
      state.Certificate7 = Certificate7.length
      state.Certificate8 = Certificate8.length
      state.Certificate9 = Certificate9.length
      state.Certificate10 = Certificate10.length


      
// const uniqueArray = [...new Set(action.payload.map(c => c.created))];


//       console.log(uniqueArray);
//       // const uniqueDates = state.
     
    },
    [getUsers.rejected]: (state, action) => {
    
      state.loading = false;
      state.error = action.payload;
    },
    [userBadgePrinted.pending]: (state, action) => {
      state.loading = true;
    },
    [userBadgePrinted.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [userBadgePrinted.rejected]: (state, action) => {

      state.loading = false;
      state.error = action.payload;
    },
    [authuser.pending]: (state, action) => {
      // state.loading = true;
    },
    [authuser.fulfilled]: (state, action) => {
      state.volunteers  = action.payload
    },
    [authuser.rejected]: (state, action) => {
    },
    [addauthuser.pending]: (state, action) => {
      // state.loading = true;
    },
    [addauthuser.fulfilled]: (state, action) => {

      console.log('action',action)
      const {
        arg: { formData },
      } = action.meta;
     state.volunteers = [... state.volunteers,formData];  

    
    },
    [addauthuser.rejected]: (state, action) => {
    },
    [addexhibitor.pending]: (state, action) => {
      // state.loading = true;
    },
    [addexhibitor.fulfilled]: (state, action) => {

      console.log('action',action)
      const {
        arg: { formData },
      } = action.meta;
     state.volunteers = [... state.volunteers,formData];  

    
    },
    [addexhibitor.rejected]: (state, action) => {
    },
    [deleteVolenteer.fulfilled]: (state, action) => {

      console.log('redux delete',action)
      const {
        arg: { id },
      } = action.meta;
      state.volunteers = state.volunteers.filter((item) => item.id !== id);
     state.volunteers = [... state.volunteers];

    },





    /////////////////////////////////////////////////
    [updateUserForm.fulfilled]: (state, action) => {
      console.log(action,'xxactionxx')
      const {
        arg: { data },
      } = action.meta;
      console.log(data.reg_num,'data.reg_num')

      state.users = state.users.filter((item) => item.reg_num !== data.reg_num);
     state.users = [... state.users,data];
    },
    [updateauthuser.fulfilled]: (state, action) => {
      console.log('-----------',action.meta,state.volunteers,'------------')
      const {
        arg: { formData },
      } = action.meta;
      state.volunteers = state.volunteers.filter((item) => item.id !== formData.id);
     state.volunteers = [... state.volunteers,formData];
    },
    [updateexhibitor.fulfilled]: (state, action) => {
      console.log('-----------',action.meta,state.volunteers,'------------')
      const {
        arg: { formData },
      } = action.meta;
      state.volunteers = state.volunteers.filter((item) => item.id !== formData.id);
     state.volunteers = [... state.volunteers,formData];
    },
    /////////////////////////////////////////////////
    [addUser.pending]: (state, action) => {
      state.loading = true;
    },
    [addUser.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { data },
      } = action.meta;
     state.users = [... state.users,data];

    },
    [addUser.rejected]: (state, action) => {

      state.loading = false;
      state.error = action.payload;
    },
    [saveDelegateData.pending]: (state) => {
      state.loading = true;
    },
    [saveDelegateData.fulfilled]: (state) => {
      state.loading = false;
    },
    [saveDelegateData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [fetchExbDataById.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchExbDataById.fulfilled]: (state,action) => {
      state.loading = false;
      state.exbdata = action.payload;
    },
    [fetchExbDataById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
})
// Action creators are generated for each case reducer function
export const { changePrintstatus,changeCertificatestatus } = userSlice.actions;

export default userSlice.reducer
