import React, { useState } from 'react';
import { Container,Card ,Button,Stack,Box,CardHeader} from '@mui/material';
import Iconify from '../components/iconify';
import DeleteModal from './modal/DeleteModal';

const DeleteCsv = () => {


  const [openformmodal, setOpenFormModal] = useState(false);
  
  const handleFormOpen = () => {
    setOpenFormModal(true);
  };

  const callback = ()=>{
    setOpenFormModal(false)
   }
  
    
  return (
<>
<Container>
     <Box sx={{ pt: 2 }}>
     <Card>
     <CardHeader title={'Delete CSV'}/>
     <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} style={
          {padding:'20px'}
        }>
    <Button variant="contained" startIcon={<Iconify icon="oi:trash" />} onClick={() => handleFormOpen()}>
    Delete Data
          </Button>
     </Stack>
     </Card>
          </Box>

 </Container>
 <div>
 <DeleteModal
 openformmodal={openformmodal}
 callback={callback}
 />
 </div>
</>
     

  )
}

export default DeleteCsv


