
import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { FileUpload } from '../FileUpload';
import TabPanel1 from './TabPanel1'
import TabPanel2 from './TabPanel2';
import TabPanel3 from './TabPanel3';
import TabPanel4 from './TabPanel4';
import TabPanel5 from './TabPanel5';
import TabPanel6 from './TabPanel6';
import { getCerSettings, getfont } from 'src/redux/userSlice';
import { useDispatch,useSelector } from 'react-redux';



export default function CenteredTabs() {
  const dispatch  = useDispatch()

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(getCerSettings());
    dispatch(getfont());
  },[]);
  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
            <Tab label="Upload CSV / Delete CSV" value="1" />
            <Tab label="Add Volunteers" value="3" />
            <Tab label="Setting" value="2" />
            <Tab label="Certificate" value="4" />
            <Tab label="Qr Code" value="5" />
            <Tab label="Exhibitor" value="6" />
          </TabList>
        </Box>
        <TabPanel value="1"><TabPanel1/></TabPanel>
        <TabPanel value="3"><TabPanel4/></TabPanel>
        <TabPanel value="2"><TabPanel2/></TabPanel>
        <TabPanel value="4"><TabPanel3/></TabPanel>
        <TabPanel value="5"><TabPanel5/></TabPanel>
        <TabPanel value="6"><TabPanel6/></TabPanel>
      </TabContext>
    </Box>
  );
}