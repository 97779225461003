import React from 'react';

import QRCode from 'react-qr-code';


export const QrCodePage = (props) => {
  console.log('props',props)
      return (
  <>
{/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "0 auto" }}>
  <div style={{ marginRight: "20px" }}>
    <QRCode size={100} value={props.qrvalue} viewBox={`0 0 256 256`} />
  </div>
  <h3 style={{ textAlign: "center", fontWeight: "bold", fontSize: "15px", margin: 0 }}>
    {props.attende}
  </h3>
</div> */}
<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "0 auto" }}>
  <div style={{ marginBottom: "10px" }}>
    <QRCode size={100} value={props.qrvalue} viewBox={`0 0 256 256`} />
  </div>
  <h3 style={{ textAlign: "center", fontWeight: "bold", fontSize: "19px", margin: 0 }}>
  {props.attende}
  </h3>
  <h3 style={{ textAlign: "center", fontWeight: "bold", fontSize: "15px", margin: 0 }}>
  {props.note}
  </h3>
</div>
  </>
  )
}
