import React,  { useEffect } from 'react'
import { Grid, Container, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector } from "react-redux";
import { getSettings,getCerSettings} from 'src/redux/userSlice';

const Certificate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());
    dispatch(getCerSettings());

  },[]);
  const  {settings}  = useSelector((state) => ({ ...state.users}));

  const List = settings?.filter(item => item.type == 'certificate');
  return (
<>

<Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
        Ceritficate
        </Typography>
        <Grid container spacing={3}>
          { List?.map((cert,index) => (<Grid item xs={12} sm={6} md={3}>
          <div className="card" style={{ 
    borderRadius : '8px',
    width: '215px',
    height: '215px',
    backgroundColor: '#3d3b3d',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden'
    }}
    onClick={() => navigate(`/scan/${cert.dbname}`)}>
    {/* onClick={() => navigate(`dashboard/scan/${cert.halltype}`)}> */}
      <h2 className="card-title" style={{fontSize: '1.5rem',color:'white'}}>{cert.name ? cert.name : 'NA'}</h2>
    </div> 
          </Grid>
          ))}
         </Grid>
       
        </Container>

    
</>
  )
}

export default Certificate