const jwtToken = localStorage.getItem('auth');

const decodeJwt = (token) => {
  if (!token) {
    console.error('JWT token is missing.');
    return null;
  }

  const parts = token.split('.');

  if (parts.length !== 3) {
    console.error('Invalid JWT format. Expected three parts separated by dots.');
    return null;
  }

  const base64Url = parts[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  try {
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((char) => {
          return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Error decoding JWT:', error.message);
    return null;
  }
};

const decodedPayload = decodeJwt(jwtToken);


console.log('decodeJwt', decodedPayload)
const account = {
  displayName: `${decodedPayload ? decodedPayload.UserInfo.email : null}`,
  email: `${decodedPayload ? decodedPayload.UserInfo.roles : null}`,
  photoURL: '/assets/images/avatars/avatar_default.jpg',
};

export default account;


