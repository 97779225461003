import PropTypes from 'prop-types';
import { useEffect,useState  } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import SvgColor from '../../../components/svg-color';

//
// import navConfig from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [decodedPayl, setdecodedPayl] = useState(null);

  const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
  const [navConfig, setNavConfig] = useState([
    {
      title: 'dashboard',
      path: '/app',
      icon: icon('ic_analytics'),
    },
    {
      title: 'Attendees',
      path: '/Attendees',
      icon: icon('ic_user'),
    },
    {
      title: 'Certificate',
      path: '/Certificate',
      icon: icon('ic_certificate'),
    },
    {
      title: 'Food Scan/ Certificate',
      path: '/foodscan',
      icon: icon('ic_food'),
    },
    {
      title: 'Kit Bag',
      path: '/workshop',
      icon: icon('ic_workshop'),
    },
    // {
    //   title: 'Self checkIN',
    //   path: '/Selftest',
    //   icon: icon('ic_workshop'),
    // },
    // {
    //   title: 'Head / Entry Count',
    //   path: '/headCount',
    //   icon: icon('ic_user'),
    // },
    // {
    //   title: 'Kitbag',
    //   path: '/kitbag',
    //   icon: icon('ic_kitbag'),
    // },

  ]);

  useEffect(() => {
    const jwtToken = localStorage.getItem('auth');
    const decodeJwt = (token) => {
      if (!token) {
        console.error('JWT token is missing.');
        return null;
      }
    
      const parts = token.split('.');
      
      if (parts.length !== 3) {
        console.error('Invalid JWT format. Expected three parts separated by dots.');
        return null;
      }
    
      const base64Url = parts[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      
      try {
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map((char) => {
              return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
        );
    
        return JSON.parse(jsonPayload);
      } catch (error) {
        console.error('Error decoding JWT:', error.message);
        return null;
      }
    };
    const decodedPayload = decodeJwt(jwtToken);

    if (decodedPayload && decodedPayload.UserInfo.roles == 'admin') {
      setIsAdmin(true);
     const adminItem = {
        title: 'Admin',
        path: '/Admin',
        icon: icon('ic_user'),
      }
      setNavConfig([...navConfig,adminItem])
    }else if (decodedPayload && decodedPayload.UserInfo.roles === 'exhibitor') {
      setNavConfig((prevNavConfig) => [
        {
          title: 'Scan Badge',
          path: '/ScanBadge',
          icon: icon('scan'),
        },
        {
          title: 'ExbhitorDashboard',
          path: '/ExbhitorDashboard',
          icon: icon('ic_analytics'),
        },

      ]);
    }
    
    setdecodedPayl(decodedPayload)

  }, []);


  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

     
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}