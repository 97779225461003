import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TextField, Button, Container } from '@mui/material';
import usersapis from 'src/apis/usersapis';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function UploadModal(props) {

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('csv', props.file ? props.file : null);

      await usersapis.post('users/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      props.callback()
      toast.success('CSV data uploaded successfully!', {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Error uploading CSV. Please check the console for details.', {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleClose = () => {
    props.callback()
  };

  return (
    <div>
      <BootstrapDialog
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.openformmodal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {'Upload CSV'}
        </DialogTitle>
        <IconButton

          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Container>
          <DialogContent dividers>
            <Typography variant="h6" gutterBottom>
              Are you sure you want to upload the CSV
            </Typography>

          </DialogContent>
        </Container>
        <DialogActions>
          <Button variant="contained" color="secondary" type="submit" onClick={handleUpload}>
            Yes
          </Button>
          <Button variant="contained" color="error" type="submit" onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}


