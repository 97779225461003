import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Button, Container, TextField } from '@mui/material';
import usersapis from 'src/apis/usersapis';
import jsQR from "jsqr";
import { jsPDF } from "jspdf";
import QRCode from "qrcode";
import React, { useState, useEffect } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

export default function SelfcheckinModal(props) {
    const [name, setName] = useState("");
    const [value, setValue] = useState("");
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (props?.userName) {
            setName(props.userName);
        }
        if (props?.scannedValue) {
            setValue(props.scannedValue);
        }
    }, [props.userName, props.scannedValue]);

    const handleDelete = async (name, value) => {
        try {
            const doc = new jsPDF();
            doc.setFontSize(16);
            const qrImage = await QRCode.toDataURL(value, { width: 128 });
            doc.addImage(qrImage, "PNG", 20, 20, 128, 128);
            doc.text(`${name}`, 20, 160);
            const pdfBlob = doc.output("blob");
            const formData = new FormData();
            formData.append("file", pdfBlob, "QRCode.pdf");
      
            const response = await usersapis.post('users/print', formData);

            if (!response.ok) {
                throw new Error("Failed to print the PDF.");
            }
        } catch (error) {
            console.error("Error generating PDF or sending to printer:", error);
        } finally {
            toast.success(`${name} Your badge has been printed successfully. Thank you!`, {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            handleClose();
        }
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveName = () => {
        setIsEditing(false);
    };

    const handleClose = () => {
        props.callback();
    };

    return (
        <div>
            <BootstrapDialog
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.openformmodal}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {isEditing ? (
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    ) : (
                        name ? name : ''
                    )}
                </DialogTitle>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Container>
                    <DialogContent dividers>
                        <Typography variant="h6" gutterBottom>
                            Click the 'Edit' button to modify your name.
                        </Typography>
                    </DialogContent>
                </Container>
                <DialogActions>
                    {isEditing ? (
                        <Button variant="contained" color="primary" onClick={handleSaveName}>
                            Save Name
                        </Button>
                    ) : (
                        <Button variant="contained" color="info" onClick={handleEditClick}>
                            Edit Name
                        </Button>
                    )}
                    <Button variant="contained" color="secondary" onClick={() => handleDelete(name, value)}>
                        Print Badge
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
