import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { TextField, Button, Container, Stack } from '@mui/material';

import { addauthuser, updateauthuser } from 'src/redux/userSlice';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function VolModal(props) {
  const dispatch = useDispatch();
  const { volunteers } = useSelector((state) => ({ ...state.users }));

  const initialState = {
    email: "",
    password: "",
    role: "user"

  }

  const [formData, setFormData] = useState(initialState)

  const { email, password } = formData

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClose = () => {
    props.callback()
    setFormData(initialState)
  };

  useEffect(() => {
    if (props.id) {
      const user_id = volunteers?.filter(id => id.id == props.id)
      console.log('user_id', user_id)
      setFormData(...user_id)
    }
    if (!props.edit) {
      setFormData(initialState)
    }
  }, [props.edit])
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!props.edit) {
      dispatch(addauthuser({ formData, toast }));
    } else {
      dispatch(updateauthuser({ formData, toast }));

    }
    clearform()
    handleClose()
  };


  const clearform = () => {
    setFormData(initialState)
  }
  return (
    <div>
      <BootstrapDialog
        fullWidth={true}
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.openformmodal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {props.edit ? 'Edit Volunteer' : 'Add Volunteer'}
        </DialogTitle>
        <IconButton

          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit}>
          <Container>
            <DialogContent dividers>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>

                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="User_Name"
                  onChange={onInputChange}
                  value={email}
                  name="email"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Password"
                  onChange={onInputChange}
                  value={password}
                  name="password"
                  fullWidth

                  size="small"
                />
              </Stack>
            </DialogContent>
          </Container>
          <DialogActions>
            <Button variant="contained" color="secondary" type="submit">
              {props.edit ? 'Update' : 'Submit'}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}


