import CenteredTabs from './Tabs/CenteredTabs';
import { Container } from '@mui/material';

const Admin = () => {
 
  return (
    <>
  <Container>
  <CenteredTabs/>
  </Container>
    </>
   
  );
};

export default Admin;