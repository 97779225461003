import { useParams,useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getUsers,saveDelegateData } from '../redux/userSlice';
import { Card, CardContent, Typography, Box, TextField, Button, CircularProgress } from '@mui/material';

const SaveDelegateData = () => {
    const navigate = useNavigate();

  const { code } = useParams();
  const dispatch = useDispatch();
  const { users, error, loading } = useSelector((state) => ({ ...state.users }));
  const [comment, setComment] = useState("");
  const [exbid, setExbId] = useState("");
  
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    const jwtToken = localStorage.getItem('auth');
    const base64Url = jwtToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const decodedPayload = JSON.parse(atob(base64));
    setExbId(decodedPayload.UserInfo.id)
  }, []);

  // Filter delegate only after users are fetched
  const delegate = users?.filter(user => user.reg_num === code);
  const categoryMap = {
    '2': 'Delegate',
    '6': 'Faculty',
    // Add more mappings if needed
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSave = () => {
    console.log('exbid',exbid)
    const categoryLabel = categoryMap[delegate[0].category] || delegate[0].category;
    const data = { ...delegate[0], comment, category: categoryLabel, exbid };
    dispatch(saveDelegateData(data));
    navigate(`/ExbhitorDashboard`);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh', padding: 2 }}>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">Error loading data</Typography>
      ) : delegate && delegate.length > 0 ? (
        <Card sx={{ maxWidth: 500, width: '100%' }}>
          <CardContent>
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="h5" gutterBottom>
                Scanned QR Code Value
              </Typography>
              <Typography variant="body1"><strong>Registration Number:</strong> {delegate[0].reg_num}</Typography>
              <Typography variant="body1"><strong>Full Name:</strong> {delegate[0].full_name}</Typography>
              <Typography variant="body1"><strong>Mobile Number:</strong> {delegate[0].mob_num}</Typography>
              <Typography variant="body1"><strong>Email ID:</strong> {delegate[0].email_id}</Typography>
              <strong>Category:</strong> {categoryMap[delegate[0].category] || delegate[0].category}
              </Box>

            <Box sx={{ marginTop: 3 }}>
              <TextField
                label="Add a Comment"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={comment}
                onChange={handleCommentChange}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: 2 }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Typography variant="body1">No delegate or Faculty found for the scanned code.</Typography>
      )}
    </Box>
  );
};

export default SaveDelegateData;
