import { createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import usersapis from 'src/apis/usersapis'
import jwtDecode from 'jwt-decode'; // You can use this library to decode JWT

export const loginapi = createAsyncThunk('user/login', async ({ formData, toast, navigate }) => {
  try {
    const response = await usersapis.post('auth/', formData);


    if (response.status === 200) {

      const decodeJwt = (token) => {
        const base64Url = token?.split('.')[1];
        const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = base64 ?  decodeURIComponent(
          atob(base64)
            ?.split('')
            ?.map((char) => {
              return '%' + ('00' + char?.charCodeAt(0)?.toString(16))?.slice(-2);
            })
            .join('')
        ) : null
      
        return JSON.parse(jsonPayload);
      };
      const token = response.data.accessToken;
      const decodedPayload = decodeJwt(token);
      const userRole =  decodedPayload.UserInfo.roles
      if (userRole === 'exhibitor') {
        navigate('/ExbhitorDashboard');
      } else {
        navigate('/app');
      }

      return response.data;
    }
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
// export const loginapi = createAsyncThunk('user/login', async ({formData,toast,navigate}) => {

//     try {
//       const response = await usersapis.post('auth/', formData)
//       console.log('response',response.message)
//       if(response.status == 200){
//         console.log('(response',response)
//           navigate('/app')
//       }
//       return response.data;
      

//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   })


const authSlice = createSlice({
    name: 'auth',
    initialState: { token: null,
      isAuthenticated :  false,
      loading : false
    },
    reducers: {
        setCredentials: (state, action) => {
            const { accessToken } = action.payload
            state.token = accessToken
        },
        logOut: (state, action) => {
            state.token = null
            state.isAuthenticated = false;
        },
    },
    extraReducers: {
        [loginapi.pending]: (state, action) => {
            state.loading = true;
            state.error = false       

          },
          [loginapi.fulfilled]: (state, action) => {

            const {accessToken} = action.payload
            state.token = accessToken
            state.isAuthenticated = true;
            localStorage.setItem('auth', JSON.stringify(accessToken));
            console.log('loginapi fullfilled')
            state.loading = false;
            state.error = false       


            // state.loading = true;
          },
          [loginapi.rejected]: (state, action) => {    
            state.error = true       
          },

    }




    
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

