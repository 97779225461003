import React,{ useState,useEffect }from 'react';
import { useDispatch,useSelector } from "react-redux";
import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TextField, Button, Container, Stack, Box, FormControlLabel, Checkbox, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import {addUser,updateUserForm } from '../redux/userSlice';
import { updateSettings } from '../redux/userSlice';

import { getSettings } from '../redux/userSlice';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function SettingModal(props) {

   
console.log('props',props)
  const dispatch = useDispatch();
  const  {settings}  = useSelector((state) => ({ ...state.users}));

  const List = settings?.filter(item => item.type == props.type);

  useEffect(() => {
    dispatch(getSettings());
  },[]);
  const [formData, setFormData] = useState([]);




  const handleInputChange = (event, dbname) => {
    const updatedFormData = formData.map(item => {
      if (item.dbname === dbname) {
        // Update the 'name' property for the corresponding item
        return { ...item, name: event.target.value };
      }
      return item;
    });

    setFormData(updatedFormData)
  };


  useEffect(()=>{
        setFormData(List)
  },[props.type])



  const handleClose = () => {
    props.callback()
    // setFormData(initialState)
  };





  const handleSubmit = (e) => {


 e.preventDefault();
    dispatch(updateSettings({formData,toast}));
 

    props.callback()
//  clearform()
  };


  return (
    <div>
      <BootstrapDialog
        fullWidth={true}
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.openformmodal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {props.name ? props.name : 'apple'}
        </DialogTitle>
        <IconButton

          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <form  onSubmit={handleSubmit}>
          <Container>
            <DialogContent dividers>


                 { props?.type ? 
                    formData?.map(item => (
                        <Stack  key={item.dbname} spacing={3} direction="row" sx={{ marginBottom: 4 }}>
                        <TextField
                           type="text"
                           variant='outlined'
                           color='secondary'
                          value={item.name}
                          name={item.dbname}
                          onChange={(e) => handleInputChange(e, item.dbname)}
                          size="small"
                          fullWidth
                          label={item.dbname}
                        />
                        </Stack>
                      )) : null}
                    
                
                
{/* { props?.type == "certificate" ? <div>
            <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>

            <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Certificate 1"
                  onChange={onInputChange}
                  value={'apple'}
                  name="certificate1"
                  size="small"
                  fullWidth

                />
          
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Certificate 2"
                  onChange={onInputChange}
                  value={'apple'}
                  name="certificate1"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Certificate 3"
                  onChange={onInputChange}
                  value={'apple'}
                  name="certificate1"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Certificate 4"
                  onChange={onInputChange}
                  value={'apple'}
                  name="certificate1"
                  size="small"
                  fullWidth

                />
                    </Stack>
                    <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Certificate 5"
                  onChange={onInputChange}
                  value={'apple'}
                  name="certificate1"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Certificate 6"
                  onChange={onInputChange}
                  value={'apple'}
                  name="certificate1"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Certificate 7"
                  onChange={onInputChange}
                  value={'apple'}
                  name="certificate1"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Certificate 8"
                  onChange={onInputChange}
                  value={'apple'}
                  name="certificate1"
                  size="small"
                  fullWidth

                />
                 </Stack>
                 <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Certificate 9"
                  onChange={onInputChange}
                  value={'apple'}
                  name="certificate1"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Certificate 10"
                  onChange={onInputChange}
                  value={'apple'}
                  name="certificate1"
                  size="small"
                  fullWidth

                />


          
          </Stack>
</div> : null } */}

{/* 
 { props?.type == "lunch" ? <div>
        <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="d1 Lunch"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="d2 Lunch"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="d3 Lunch"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="d4 Lunch"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="d5 Lunch"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
          </Stack>
          <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
          <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Day1 Dinner"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Day2 Dinner"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Day3 Dinner"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Day4 Dinner"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Day5 Dinner"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />    
          </Stack> 
          </div> : null}
 { props?.type == "hall" ? <div>
        <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="hall A"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="hall B"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="hall C"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="hall D"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
          </Stack>
 
          </div> : null}
 { props?.type == "work_shop" ? <div>
        <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Work Shop 1"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Work Shop 2"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Work Shop 3"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Work Shop 4"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Work Shop 5"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Work Shop 6"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Work Shop 7"
                  onChange={onInputChange}
                  value={'apple'}
                  name="d1_lunch"
                  size="small"
                  fullWidth
                />
          </Stack>
 
          </div> : null} */}












          
           {/* <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Mobile Number"
                  onChange={onInputChange}
                  value={mob_num}
                  name="mob_num"
                  fullWidth

                  size="small"
                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Email ID"
                  onChange={onInputChange}
                  value={email_id}
                  name="email_id"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="City"
                  onChange={onInputChange}
                  value={city}
                  name="city"
                  fullWidth

                  size="small"
                />
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="categorey"
                    value={category}
                    name="category"
                    onChange={onInputChange}
                    size="small"
                  >
                    <MenuItem value={'1'}>Delegate</MenuItem>
                    <MenuItem value={'2'}>Faculty</MenuItem>
                    <MenuItem value={'3'}>Members</MenuItem>
                    <MenuItem value={'4'}>Accompany person</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Note"
                  onChange={onInputChange}
                  value={note}
                  name="note"
                  fullWidth
                  size="small"
                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Refrence"
                  value={reference}
                  name="reference"
                  fullWidth
                  size="small"
                  onChange={onInputChange}
                />
              </Stack> */}
              <Typography variant="h6" gutterBottom>
      </Typography>
            </DialogContent>
          </Container>

          <DialogActions>
            <Button variant="contained" color="secondary" type="submit">
  
              {props.edit ? 'Update' : 'Submit'}
              </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}


