import { useState,useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logOut } from 'src/redux/authSlice';
import techengagelogo from '../../../techengagelogos/te2.png'; 
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch  =  useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(null);
  const [decodedPayl, setdecodedPayl] = useState(null);


  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem('auth');
    const decodeJwt = (token) => {
      if (!token) {
        console.error('JWT token is missing.');
        return null;
      }
    
      const parts = token.split('.');
      
      if (parts.length !== 3) {
        console.error('Invalid JWT format. Expected three parts separated by dots.');
        return null;
      }
    
      const base64Url = parts[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      
      try {
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map((char) => {
              return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
        );
    
        return JSON.parse(jsonPayload);
      } catch (error) {
        console.error('Error decoding JWT:', error.message);
        return null;
      }
    };
    const decodedPayload = decodeJwt(jwtToken);

    setdecodedPayl(decodedPayload)

  }, []);
  const handleLogout = () => {
    dispatch(logOut())
    navigate('/login')
    localStorage.removeItem('auth');
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={techengagelogo} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
          {decodedPayl ? decodedPayl.UserInfo.email : '-'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          { decodedPayl ? decodedPayl.UserInfo.roles : '-'}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
