import React from 'react'
import { FileUpload } from '../FileUpload'
import DeleteCsv from '../DeleteCsv'

import { DownloadCsv } from '../DownloadCsv'

const TabPanel1 = () => {
  return (
    <>
    <FileUpload/>
    <DeleteCsv/>
    <DownloadCsv/>
    </>
  )
}

export default TabPanel1