import React from 'react'
import Settings from '../Settings'

const TabPanel2 = () => {
  return (
   <>
   <Settings/>
   </>
  )
}

export default TabPanel2