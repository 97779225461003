import * as React from 'react';
import { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Dialog from '@mui/material/Dialog';
import { TextField, Button, Container, Stack, Box,Card,CardHeader} from '@mui/material';
import { cersettings,updateFontSize } from 'src/redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const TabPanel3 = () => {

  const { cersettingsData,fontSize } = useSelector((state) => ({ ...state.users }));

console.log('XfontSizeX',fontSize)

  const initialState = {
    right: '20',
    top: '20'
  }


  const dispatch = useDispatch()
  const [values, setValue] = useState(initialState);
  const [fonts, setFonts] = useState('');

  const { right, top } = values


  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setValue({ ...values, [name]: value });
  };



  useEffect(() => {


    const { top_px, right_px } = cersettingsData[0];


    const topValue = top_px.slice(0, -2); // Removes the last two characters ("px")
    const rightValue = right_px.slice(0, -2); // Removes the last two characters ("px")
    setValue({ top: topValue, right: rightValue })
    setFonts(fontSize)
  }, [])


  const onInputChange = (e) => {
   
    setFonts(e.target.value);
  };


  const handleChange = () => {

    dispatch(cersettings({ top, right, toast }))
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateFontSize({fonts,toast}));
     };


  

  return (
    <>
      <div>

      <Container>
        <Box sx={{ pt: 2 }}>
        <Card>
        <CardHeader title={'Dimensions'}/>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} style={
          {padding:'20px'}
        }>
      
      <Box sx={{ width: 200 }}>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <ArrowForwardIcon />
            <Slider
              name='top'
              valueLabelDisplay="auto"
              min={0}
              max={900}
              aria-label="Volume" value={top} onChange={handleChangeValue} />
          </Stack>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <ArrowDownwardIcon />

            <Slider
              name='right'
              valueLabelDisplay="auto"
              min={0}
              max={900}
              aria-label="Volume" value={right} onChange={handleChangeValue} />
          </Stack>
          <Button variant="contained" onClick={handleChange}>
            save
          </Button>
        </Box>
      
        </Stack>
        </Card>
             </Box>
    </Container>



    <Container>
        <Box sx={{ pt: 2 }}>
        <Card>
        <CardHeader title={'Font Size'}/>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} style={
          {padding:'20px'}
        }>
      
      <form  onSubmit={handleSubmit}>
        
         
          
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Font Size"
                  onChange={onInputChange}
                  value={fonts}
                  name="font"
                  size="small"
                  fullWidth

                />


           <Stack style={{marginTop:'10px'}}>
           <Button variant="contained" color="secondary" type="submit">
              {'Update'}
              </Button>
           </Stack>
           
        
        </form>
      
        </Stack>
        </Card>
             </Box>
    </Container>






        <div>
      
   
    </div>
      </div>
    </>

  )
}

export default TabPanel3