import React from 'react'
import { CircularProgress,Backdrop } from '@mui/material'

const BackdropLoading = (props) => {
  return (
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={props.loading}
 
  >
    <CircularProgress color="inherit" />
  </Backdrop>
  )
}

export default BackdropLoading

