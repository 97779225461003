import React from 'react';
import { toast } from "react-toastify";
import { Container,Card ,Button,Stack,Box,CardHeader} from '@mui/material';
import Iconify from '../components/iconify';
import usersapis from 'src/apis/usersapis';



export const DownloadCsv = () => {
  const handleDownload = async () => {
    try {
      const response = await usersapis.get('users/download-csv', { responseType: 'blob' });

      console.log('res',response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'table_data.csv');
      document.body.appendChild(link);
      link.click();
      toast.success('CSV  Downloaded successfully!', {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
    } catch (error) {
      toast.error('Error downloading CSV.', {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      console.error('Error downloading CSV:', error);
    }
  };

  return (
    <div>
      <div>
      <Container>
        <Box sx={{ pt: 2 }}>
        <Card>
        <CardHeader title={'Download CSV'}/>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} style={
          {padding:'20px'}
        }>
      <Button variant="contained" startIcon={<Iconify icon="fa-solid:file-csv" />} onClick={handleDownload}>
      Download CSV
          </Button>
        </Stack>
        </Card>
             </Box>
    </Container>
    </div>
  
    </div>
  );
}