import React, { useRef, useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import jsQR from 'jsqr';
import { useNavigate } from 'react-router-dom';
import beepSound from '../components/beepSound.mp3'; // Adjust the path according to your file location

const ScanBadge = () => {
  const navigate = useNavigate();
  const [qrCodeValue, setQrCodeValue] = useState(null);
  const webcamRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (webcamRef.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          const img = new Image();
          img.src = imageSrc;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height);
            if (code && code.data) {
              console.log('Scanned data:', code.data);
              setQrCodeValue(code.data);
              const audio = new Audio(beepSound);
              audio.play();
              navigate(`/SaveDelegateData/${code.data}`);
              clearInterval(interval);
            }
          };
        }
      }
    }, 300); // Adjust the interval for your scanning needs

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div style={{ 
      position: 'fixed', 
      top: 0, 
      left: 0, 
      width: '100vw', 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      backgroundColor: 'black',
      zIndex: 9999
    }}>
      <h1 style={{
        color: 'white',
        marginBottom: '20px',
        zIndex: 10000
      }}>
        Scan Badge
      </h1>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          facingMode: { exact: "environment" } // Ensures the back camera is used
        }}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </div>
  );
};

export default ScanBadge;
