import React, { useEffect,useState } from 'react'
import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import {  BlogPostCard, BlogPostsSort, BlogPostsSearch } from 'src/sections/@dashboard/blog';
// mock
// import POSTS from '../_mock/blog';
import POSTS from 'src/_mock/blog';
import { useDispatch,useSelector } from 'react-redux';

import { authuser } from '../redux/userSlice';

import VolModal from './modal/VolModal';


// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

const Volun = () => {
    const dispatch = useDispatch()

  const [openformmodal, setOpenFormModal] = useState(false);
  const[id,setID] = useState(null);

  const[edit,setEdit] = useState(false);

 const {volunteers} = useSelector((state) => ({ ...state.users}));
 const volunt = volunteers?.filter( users => users.role == 'user')
useEffect(()=>{
  dispatch(authuser())
},[])

const callback = ()=>{
  setOpenFormModal(false)
  setEdit(false)
 }
const handleFormOpen = () => {
  setOpenFormModal(true);
  setEdit(false)
};
const handleEdit = (reg_num)=>{
    // setEdit(true)
    // setModalregnum(reg_num)
    // setOpenFormModal(true);
  }
const usersDetailesEdit = (id)=>{
    setEdit(true)
    setID(id)
    setOpenFormModal(true);
  }
  
  return (
    <>
      <Helmet>
        <title> Dashboard: Blog | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Volunteer
          </Typography>
          <Button onClick={handleFormOpen} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
Volunteer</Button>
        </Stack>

        <Grid container spacing={3}>
          {volunt?.map((volunt, index) => (
            <BlogPostCard  key={volunt.id} volunt={volunt} index={index} usersDetailesEdit={usersDetailesEdit} />
          ))}
        </Grid>
      </Container>
      <VolModal 
      openformmodal={openformmodal}
      callback={callback}
      handleEdit={handleEdit}
      edit={edit}
      id={id}
      />
    </>
  )
}

export default Volun