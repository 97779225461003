import React from 'react'
import { Grid, Container, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";

const KitbagScan = () => {
    const navigate = useNavigate();
    const cnames = [
      {
        halltype:'kitbag',
        name :'Kit Bag',
      },
    ]
  return (
    <>
    <Container maxWidth="xl">
    <Typography variant="h4" sx={{ mb: 5 }}>
Kit Bag    </Typography>
    <Grid container spacing={3}>
      { cnames.map((cert,index) => (<Grid item xs={12} sm={6} md={3}>
      <div className="card" style={{ 
borderRadius : '8px',
width: '215px',
height: '215px',
backgroundColor: '#3d3b3d',
borderRadius: '10px',
display: 'flex',
flexDirection: 'column',
alignItems: 'center',
justifyContent: 'center',
position: 'relative',
overflow: 'hidden'
}}
onClick={() => navigate(`/FwhScan/${cert.halltype}`)}>
{/* onClick={() => navigate(`dashboard/scan/${cert.halltype}`)}> */}
  <h2 className="card-title" style={{fontSize: '1.5rem',color:'white', textAlign:'center' }}>{cert.name ? cert.name : 'NA'}</h2>
</div> 
      </Grid>
      ))}
     </Grid>
   
    </Container>
</>
  )
}

export default KitbagScan