import { toast } from "react-toastify";
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from "react-router-dom";
import { updateUser } from 'src/redux/userSlice';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import React, { useRef, useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import jsQR from 'jsqr';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  FormControlLabel,
  Switch,
  FormGroup,
} from '@mui/material';
import { ScanDetection } from './ScanDetection';
import TableComponent from './Table';
import { UserListHead } from '../sections/@dashboard/user';
import QrScanner from 'react-qr-scanner';
import beepSound from '../components/beepSound.mp3'; // Adjust the path according to your file location

const FwhScan = () => {
  const TABLE_HEAD = [
    { id: 'name', label: 'Reg.No', alignRight: false },
    { id: 'company', label: 'Full Name', alignRight: false },
    { id: 'role', label: 'Category', alignRight: false },
    { id: 'isVerified', label: 'Email', alignRight: false },
    { id: 'isVerified', label: 'Mobile', alignRight: false },
  ];

  const { users } = useSelector((state) => ({ ...state.users }));
  // const [scannedData, setScannedData] = useState(null);
  const [checked, setChecked] = React.useState(false);
  const [checkedmobile, setCheckedMobile] = React.useState(false);
  // const [scannerKey, setScannerKey] = useState(0); // Key to force remount
  const [scanAllowed, setScanAllowed] = useState(true);

  const { halltype } = useParams();
  const dispatch = useDispatch();

  const qrvalue = ScanDetection();
  const reg_num = qrvalue ? qrvalue.replaceAll('Shift', '') : null;
  const userdatailes = users.filter(user => user.reg_num === reg_num);

  useEffect(() => {
    if (reg_num) {
      dispatch(updateUser({ reg_num, halltype, toast }));
    }
  }, [reg_num, halltype, dispatch]);
  const navigate = useNavigate();
  const webcamRef = useRef(null);

  useEffect(() => {
    const startScanning = () => {
      const interval = setInterval(() => {
        if (webcamRef.current && scanAllowed) {
          const imageSrc = webcamRef.current.getScreenshot();
          if (imageSrc) {
            const img = new Image();
            img.src = imageSrc;
            img.onload = () => {
              const canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
              const code = jsQR(imageData.data, imageData.width, imageData.height);

              if (code && code.data) {
                console.log('code.data', code.data);
                new Audio(beepSound).play();
                dispatch(updateUser({ reg_num: code.data, halltype, toast }));

                setScanAllowed(false); // Stop further scanning temporarily
                clearInterval(interval);

                // Re-enable scanning after a short delay (e.g., 2 seconds)
                setTimeout(() => setScanAllowed(true), 2000);
              }
            };
          }
        }
      }, 300);

      return interval;
    };

    const interval = startScanning();
    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [navigate, scanAllowed, dispatch, halltype, toast]);


  return (
    <>
      <Helmet>
        <title>Food Scan / Certificate </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Food Scan
          </Typography>
          <FormControlLabel
            control={<Switch size="small" checked={checkedmobile} onChange={() => setCheckedMobile(!checkedmobile)} />}
            label="Mobile Scan"
          />
          <FormGroup>
            <FormControlLabel
              control={<Switch size="small" checked={checked} onChange={() => setChecked(!checked)} />}
              label="Manual Scan"
            />
          </FormGroup>
        </Stack>
        {!checked && (
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead headLabel={TABLE_HEAD} />
              <TableBody>
                {userdatailes.length
                  ? userdatailes.map((row) => {
                      const { reg_num, full_name, category, mob_num, email_id } = row;
                      return (
                        <TableRow key={reg_num}>
                          <TableCell align="left">{reg_num}</TableCell>
                          <TableCell align="left">{full_name}</TableCell>
                          <TableCell align="left">
                            {category === '1'
                              ? 'Delegate'
                              : category === '2'
                              ? 'Faculty'
                              : category === '3'
                              ? 'Members'
                              : category === '4'
                              ? 'Accompany person'
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="left">{email_id}</TableCell>
                          <TableCell align="left">{mob_num}</TableCell>
                        </TableRow>
                      );
                    })
                  : ''}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
      {checked && (
        <TableComponent qrCodevalue={qrvalue} checked={checked} halltype={halltype} FwhScan={'FwhScan'} />
      )}
      {checkedmobile && (
        
    <div style={{ 
      position: 'fixed', 
      top: 0, 
      left: 0, 
      width: '100vw', 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      backgroundColor: 'black',
      zIndex: 9999
    }}>
      <ToastContainer/>
      <h1 style={{
        color: 'white',
        marginBottom: '20px',
        zIndex: 10000
      }}>
        Scan Badge
      </h1>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          facingMode: { exact: "environment" } // Ensures the back camera is used
        }}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </div>
      )}
    </>
  );
};

export default FwhScan;
