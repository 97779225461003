import React, { useEffect } from
 
'react';
import { Navigate, Outlet } from
 
'react-router-dom';
import { useSelector, useDispatch } from
 
'react-redux';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { useMemo } from 'react';

const PrivateRoute = () => {
  const { isAuthenticated, loading } = useSelector((state) => state.auth);
  const token = localStorage.getItem('auth');
  console.log('isAuthenticated',isAuthenticated,'token',token)
  const dispatch = useDispatch();

  const renderContent = useMemo(() => {
    if (loading) {
      console.log('loading')
      return (
        <div className="loading-container">
          {/* <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
            <LinearProgress color="secondary" />
          </Stack> */}
        </div>
      );
    }

    if (!token ) {
      console.log('!token || !isAuthenticated')
      return <Navigate to="/login" />;
    }

    return <Outlet />;
  }, [loading, token]);

  return <>{renderContent}</>;
};
export default PrivateRoute;