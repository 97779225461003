import React from 'react'
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Grid,
    FormControlLabel,
    Switch,
    FormGroup,

  } from '@mui/material';
  import Iconify from '../components/iconify';
  import { Helmet } from 'react-helmet-async';
  import TableComponent from './Table';
import { useState } from 'react';
import { ScanDetection } from './ScanDetection';
import { useParams, useNavigate } from "react-router-dom";
const Scan = () => {
  const { halltype } = useParams();
  console.log('halltype',halltype)
  const [checked, setChecked] = useState(false);
  const switchHandler = (event) => {
    setChecked(event.target.checked);
  };
  const reg_num = ScanDetection()
  const qrvalue = reg_num ? reg_num.replaceAll('Shift', '') : null

  return (
    <>
     <Helmet>
        <title>Certificate Scan </title>
      </Helmet>
      <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Certificate Scan
          </Typography>
        </Stack>
      </Container>
    <TableComponent qrCodevalue={qrvalue} checked={checked} halltype={halltype}/>
    </>
  )
}
export default Scan