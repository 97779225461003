import { useState } from 'react';
import { useNavigate} from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox,FormControl,Button,Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { loginapi } from 'src/redux/authSlice';
import { useDispatch,useSelector } from 'react-redux';
import { toast } from "react-toastify";


// ----------------------------------------------------------------------

export default function LoginForm() {
  const  {error}  = useSelector((state) => ({ ...state.auth}));

  const dispatch = useDispatch();
  const navigate = useNavigate();
const initialState = {
  email : "",
  password : "",
}

  const[formData,setFormData] = useState(initialState)
  const[er,setEr] = useState(false)

  const {email,password} = formData

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
  };
  const handleSubmit = (e) => {
    console.log(formData)
    e.preventDefault();
    dispatch(loginapi({formData,toast,navigate}))

}
  

  return (
    <>
    {error ? <Alert style={{marginBottom:'20px'}} variant="filled" severity="error">Unvalid Crediantials</Alert>
 : null}
    <form  onSubmit={handleSubmit}>
    <Stack spacing={3}>
        <TextField name="email"
         label="Email address"
          onChange={onInputChange} 
          value={email}   
           error = {er}
            helperText ={er ? "Please Enter Email Id" : null}
            required
        />

        <TextField
          name="password"
          label="Password"
          type='password'
          onChange={onInputChange} 
          value={password}
        
        
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" /> */}
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <Button fullWidth size="large" type="submit" variant="contained">
        Login
      </Button>
    </form>
      
    </>
  );
}
