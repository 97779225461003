import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader } from '@mui/material';
import { styled } from '@mui/material/styles';


const CHART_HEIGHT = 392;

const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled('div')(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(2),
    '& .apexcharts-canvas svg': {
      height: CHART_HEIGHT,
    },
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
      overflow: 'visible',
    },
    '& .apexcharts-legend': {
      height: LEGEND_HEIGHT,
      alignContent: 'center',
      position: 'relative !important',
      borderTop: `solid 1px ${theme.palette.divider}`,
      top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
  }));
  
const DatewiseChart = ({users}) => {
  const uniqueDatesSet = new Set();
  users?.forEach(entry => {
    const dateObject = new Date(entry.created);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
    const day = dateObject.getDate();
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    uniqueDatesSet.add(formattedDate);
  });
  
  const uniqueDatesArray = [...uniqueDatesSet];
  
  const result = uniqueDatesArray.map(date => {
    const countP1 = users.reduce((count, entry) => {
      const entryDate = new Date(entry.created);
      const entryYear = entryDate.getFullYear();
      const entryMonth = entryDate.getMonth() + 1;
      const entryDay = entryDate.getDate();
      const entryFormattedDate = `${entryYear}-${entryMonth < 10 ? '0' : ''}${entryMonth}-${entryDay < 10 ? '0' : ''}${entryDay}`;
      return entry.printed == 1 && entryFormattedDate === date ? count + 1 : count;
    }, 0);
  
    return { date, countP1 };
  });
  
  console.log('result',result);
const filteredResult = result.filter(item => item.date !== '1970-01-01');

  const datesArray = filteredResult.map(item => item.date);
const countP1Array = filteredResult.map(item => item.countP1);

//////values


    const chartdata = {
          
        count: [{
          data: countP1Array
        }],
        options: {
          chart: {
            height: 350,
            type: 'bar',
            events: {
              click: function(chart, w, e) {
                // console.log(chart, w, e)
              }
            }
          },
        //   colors: ['red','blue','black'],
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: datesArray,
            labels: {
              style: {
                // colors: colors,
                fontSize: '12px'
              }
            }
          }
        },
      
      
      };
 

  return (
  <>
  <Card>
  <CardHeader title={'Badge Printed Day Wise'} />
  <StyledChartWrapper dir="ltr">
  <ReactApexChart options={chartdata.options} series={chartdata.count} type="bar" height={350} />
      </StyledChartWrapper>

  </Card>
 
</>
  )
}

export default DatewiseChart