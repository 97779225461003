import { Navigate, Outlet } from 'react-router-dom';

const AdminRoute = () => {

    const decodeJwt = (token) => {
        const base64Url = token?.split('.')[1];
        const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = base64 ?  decodeURIComponent(
          atob(base64)
            ?.split('')
            ?.map((char) => {
              return '%' + ('00' + char?.charCodeAt(0)?.toString(16))?.slice(-2);
            })
            .join('')
        ) : null
      
        return JSON.parse(jsonPayload);
      };
      
      // Example usage
      const jwtToken = localStorage.getItem('auth');

      const decodedPayload = decodeJwt(jwtToken);


  return jwtToken &&  decodedPayload && decodedPayload.UserInfo.roles == 'admin' ? (
    <Outlet />
  ) : (
    <Navigate to='/login' replace />
  );
};
export default AdminRoute;