import React,{ useState,useEffect }from 'react';
import { useDispatch,useSelector } from "react-redux";
import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TextField, Button, Container, Stack, Box, FormControlLabel, Checkbox, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import {addUser,updateUserForm } from '../redux/userSlice';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function FormModal(props) {
// console.log('props',props)
  const dispatch = useDispatch();
  const[user,setUser] = useState(null)
  const  {users}  = useSelector((state) => ({ ...state.users}));



// console.log(props.modalregnum,'props.modalregnum')
// console.log(props.edit,'props.edit')

  const initialState = {
    full_name: "",
    mob_num: "",
    email_id: "",
    city: "",
    state_a: "",
    country: "",
    category: "",
    note: "",
    reference: "",
    kitbag: "0",
    d1_breakfast: "0",
    d2_breakfast: "0",
    d3_breakfast: "0",
    d4_breakfast: "0",
    d5_breakfast: "0",
    d1_lunch: "0",
    d2_lunch: "0",
    d3_lunch: "0",
    d4_lunch: "0",
    d5_lunch: "0",
    d1_dinner: "0",
    d2_dinner: "0",
    d3_dinner: "0",
    d4_dinner: "0",
    d5_dinner: "0",
    food_scan: "0",
    printed: "0",
    work_shop1: "0",
    work_shop2: "0",
    work_shop3: "0",
    work_shop4: "0",
    work_shop5: "0",
    work_shop6: "0",
    work_shop7: "0",
    certificate1: "0",
    certificate2: "0",
    certificate3: "0",
    certificate4: "0",
    certificate5: "0",
    certificate6: "0",
    certificate7: "0",
    certificate8: "0",
    certificate9: "0",
    certificate10: "0",
  }

  const [formData, setFormData] = useState(initialState)
  const[generatedId,setGeneratedId] = useState({
    reg_num : null
  })
  const{reg_num} = generatedId
  const {full_name, mob_num, email_id, city, state_a, country, category, note, reference, kitbag,
    d1_breakfast, d2_breakfast, d3_breakfast, d4_breakfast, d5_breakfast, d1_lunch, d2_lunch, d3_lunch, d4_lunch, d5_lunch,
    d1_dinner, d2_dinner, d3_dinner, d4_dinner, d5_dinner, food_scan, printed, work_shop1, work_shop2, work_shop3, work_shop4, work_shop5, work_shop6,
    work_shop7, certificate1, certificate2, certificate3, certificate4, certificate5, certificate6, certificate7, certificate8, certificate9, certificate10, } = formData

  const [selectAllCheckBox, setSelectAllCheckBox] = useState(false)
  const [selectAllWorkShopCheckBox, setSelectAllWorkShopCheckBox] = useState(false)
  const [selectAllCertificateCheckBox, setSelectAllCertificateCheckBox] = useState(false)
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value});
  };

  useEffect(()=>{
    if(props.modalregnum){
      const user_id = users?.filter(id => id.reg_num == props.modalregnum)
      setFormData(...user_id)
    }
    if(!props.edit){
      setFormData(initialState)
    }
    // console.log(props.modalregnum,'props.modalregnum')
  },[props.edit])


console.log('props.edit',props.edit)

  const handleClose = () => {
    props.callback()
    setFormData(initialState)
  };

  const ischeckboxchecked = (field) => {
    return formData[field] == 1 ? true : false
  }
  const handlechekboxclick = (e, field) => {
    const checked = e.target.checked ? '1' : '0'
    setFormData({ ...formData, [field]: checked });
  }






  const handleSelectAllChekBoxClick = async (e, field) => {
    const checked = e.target.checked
    const Childchecked = e.target.checked ? '1' : '0'
    setSelectAllCheckBox(checked)
    setFormData({ ...formData,
       [field[0]]: Childchecked,
        [field[1]]: Childchecked,
        [field[2]]: Childchecked,
        [field[3]]: Childchecked,
        [field[4]]: Childchecked,
        [field[5]]: Childchecked,
        [field[6]]: Childchecked,
        [field[7]]: Childchecked,
        [field[8]]: Childchecked,
        [field[9]]: Childchecked,
        [field[10]]: Childchecked,
        [field[11]]: Childchecked,
       })
  }
  const handleSelectAllWorkShop1ChekBoxClick = async (e, field) => {
    const checked = e.target.checked
    const Childchecked = e.target.checked ? '1' : '0'
    setSelectAllWorkShopCheckBox(checked)
    setFormData({ ...formData,
       [field[0]]: Childchecked,
        [field[1]]: Childchecked,
        [field[2]]: Childchecked,
        [field[3]]: Childchecked,
        [field[4]]: Childchecked,
        [field[5]]: Childchecked,
        [field[6]]: Childchecked,
       })
  }
  const handleSelectAllCertificateChekBoxClick = async (e, field) => {
    const checked = e.target.checked
    const Childchecked = e.target.checked ? '1' : '0'
    setSelectAllCertificateCheckBox(checked)
    setFormData({ ...formData,
       [field[0]]: Childchecked,
        [field[1]]: Childchecked,
        [field[2]]: Childchecked,
        [field[3]]: Childchecked,
        [field[4]]: Childchecked,
        [field[5]]: Childchecked,
        [field[6]]: Childchecked,
        [field[7]]: Childchecked,
        [field[8]]: Childchecked,
        [field[9]]: Childchecked,
       })
  }


  useEffect(()=>{
    const generateRandomString  = (length) => {
      const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
       const randomIndex = Math.floor(Math.random() * characters.length);
       result += characters[randomIndex];
      }
      return result;
     }
     const regId =  `NAPCON-${generateRandomString(7)}`
     if(!props.edit){
      setGeneratedId({ reg_num: regId });
      // console.log('reg_num',reg_num)
     }
 
    //  !props.edit ? null : setGeneratedId({ reg_num: regId });
    //  console.log(' props.edit', props.edit)
  },[props.openformmodal])
  const handleSubmit = (e) => {
 e.preventDefault();
 let data
 if(!props.edit){
   data = {...formData,...generatedId}
    dispatch(addUser({data,toast}));
 }else{
  data = {...formData}
      dispatch(updateUserForm({data,toast}));
 }

 clearform()
 handleClose()
  };




  const clearform = ()=>{
    setFormData(initialState)
  }


        //  console.log('formData',formData)



  return (
    <div>
      <BootstrapDialog
        fullWidth={true}
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.openformmodal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {props.edit ? 'Edit Delegate' : 'Add Delegate'}
        </DialogTitle>
        <IconButton

          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <form  onSubmit={handleSubmit}>
          <Container>
            <DialogContent dividers>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>

                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Full Name"
                  onChange={onInputChange}
                  value={full_name}
                  name="full_name"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Mobile Number"
                  onChange={onInputChange}
                  value={mob_num}
                  name="mob_num"
                  fullWidth

                  size="small"
                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Email ID"
                  onChange={onInputChange}
                  value={email_id}
                  name="email_id"
                  size="small"
                  fullWidth

                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="City"
                  onChange={onInputChange}
                  value={city}
                  name="city"
                  fullWidth

                  size="small"
                />
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="categorey"
                    value={category}
                    name="category"
                    onChange={onInputChange}
                    size="small"
                  >
                    <MenuItem value={'1'}>Accompanyperson</MenuItem>
                    <MenuItem value={'2'}>Delegate</MenuItem>
                    <MenuItem value={'3'}>Executive Council</MenuItem>
                    <MenuItem value={'4'}>Executive Council Member</MenuItem>
                    <MenuItem value={'5'}>Exhibitor</MenuItem>
                    <MenuItem value={'6'}>Faculty</MenuItem>
                    <MenuItem value={'7'}>Office Bearer</MenuItem>
                    <MenuItem value={'8'}>Organizing Committe</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Note"
                  onChange={onInputChange}
                  value={note}
                  name="note"
                  fullWidth
                  size="small"
                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Refrence"
                  value={reference}
                  name="reference"
                  fullWidth
                  size="small"
                  onChange={onInputChange}
                />
              </Stack>
              <Typography variant="h6" gutterBottom>
        Not Allowed
      </Typography>
              <hr />
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleSelectAllChekBoxClick(e, ['kitbag','d1_lunch',
                       'd2_lunch', 'd3_lunch', 'd4_lunch', 
                       ,'d5_lunch','d1_dinner','d2_dinner','d3_dinner','d4_dinner','d5_dinner',])}
                      checked={selectAllCheckBox}
                    />
                  }
                  label="Select All"
                />
                <br/>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'kitbag')}
                      checked={ischeckboxchecked('kitbag')}
                    />
                  }
                  label="Kit Bag"
                /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'd1_lunch')}
                      checked={ischeckboxchecked('d1_lunch')}
                    />
                  }
                  label="16th day3  breakfast"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'd2_lunch')}
                      checked={ischeckboxchecked('d2_lunch')}
                    />
                  }
                  label="16th day3  Lunch"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'd3_lunch')}
                      checked={ischeckboxchecked('d3_lunch')}
                    />
                  }
                  label="14th day1 breakfast"
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'd4_lunch')}
                      checked={ischeckboxchecked('d4_lunch')}
                    />
                  }
                  label="Day 4 Lunch"
                /> */}
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'd5_lunch')}
                      checked={ischeckboxchecked('d5_lunch')}
                    />
                  }
                  label="Day 5 Lunch"
                /> */}

<FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'd1_dinner')}
                      checked={ischeckboxchecked('d1_dinner')}
                    />
                  }
                  label="14th day1 Lunch"
                />
<FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'd2_dinner')}
                      checked={ischeckboxchecked('d2_dinner')}
                    />
                  }
                  label="15th day2  breakfast"
                />
<FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'd3_dinner')}
                      checked={ischeckboxchecked('d3_dinner')}
                    />
                  }
                  label="15th day2 Lunch"
                />
<FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'd4_dinner')}
                      checked={ischeckboxchecked('d4_dinner')}
                    />
                  }
                  label="15th day2 Dinner"
                />
<FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'd5_dinner')}
                      checked={ischeckboxchecked('d5_dinner')}
                    />
                  }
                  label="Certificate Scan"
                />
                  <hr />
                  <br/>
                  <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleSelectAllWorkShop1ChekBoxClick(e, ['work_shop1','work_shop2','work_shop3','work_shop4','work_shop5','work_shop6','work_shop7',])}
                      checked={selectAllWorkShopCheckBox}
                    />
                  }
                  label="Select All"
                />
                <br/>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'work_shop1')}
                      checked={ischeckboxchecked('work_shop1')}
                    />
                  }
                  label="Kit Bag"
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'work_shop2')}
                      checked={ischeckboxchecked('work_shop2')}
                    />
                  }
                  label="Kit Bag - 2"
                /> */}
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'work_shop3')}
                      checked={ischeckboxchecked('work_shop3')}
                    />
                  }
                  label="TFO-01 Hall"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'work_shop4')}
                      checked={ischeckboxchecked('work_shop4')}
                    />
                  }
                  label="TFO-02 Hall"
                /> */}
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'work_shop5')}
                      checked={ischeckboxchecked('work_shop5')}
                    />
                  }
                  label="Work Shop 5"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'work_shop6')}
                      checked={ischeckboxchecked('work_shop6')}
                    />
                  }
                  label="Work Shop 6"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'work_shop7')}
                      checked={ischeckboxchecked('work_shop7')}
                    />
                  }
                  label="Work Shop 7"
                /> */}
                <hr/>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleSelectAllCertificateChekBoxClick(e, ['certificate1',
                      'certificate2',
                      'certificate3',
                      'certificate4',
                      'certificate5',
                      'certificate6',
                      'certificate7',
                      'certificate8',
                      'certificate9',
                      'certificate10',
                    ])}
                      checked={selectAllCertificateCheckBox}
                    />
                  }
                  label="Select All"
                /> */}
                <br/>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'certificate1')}
                      checked={ischeckboxchecked('certificate1')}
                    />
                  }
                  label="certificate 1"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'certificate2')}
                      checked={ischeckboxchecked('certificate2')}
                    />
                  }
                  label="certificate 2"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'certificate3')}
                      checked={ischeckboxchecked('certificate3')}
                    />
                  }
                  label="certificate 3"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'certificate4')}
                      checked={ischeckboxchecked('certificate4')}
                    />
                  }
                  label="certificate 4"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'certificate5')}
                      checked={ischeckboxchecked('certificate5')}
                    />
                  }
                  label="certificate 5"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'certificate6')}
                      checked={ischeckboxchecked('certificate6')}
                    />
                  }
                  label="certificate 6"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'certificate7')}
                      checked={ischeckboxchecked('certificate7')}
                    />
                  }
                  label="certificate 7"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'certificate8')}
                      checked={ischeckboxchecked('certificate8')}
                    />
                  }
                  label="certificate 8"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'certificate9')}
                      checked={ischeckboxchecked('certificate9')}
                    />
                  }
                  label="certificate 9"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handlechekboxclick(e, 'certificate10')}
                      checked={ischeckboxchecked('certificate10')}
                    />
                  }
                  label="certificate 10"
                /> */}
              </div>

            </DialogContent>
          </Container>

          <DialogActions>
            <Button variant="contained" color="secondary" type="submit">
  
              {props.edit ? 'Update' : 'Submit'}
              </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}


