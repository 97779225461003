import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useRef, useState, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Modal,
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { getUsers,getCerSettings,userBadgePrinted,changeCertificatestatus,updateUser,getfont} from '../redux/userSlice';
import { QrCodePage } from './QrCodePage';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import FormModal from './FormModal';
import BackdropLoading from './BackdropLoading';




// ----------------------------------------------------------------------



// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  

export default function TableComponent({checked,qrCodevalue,halltype,FwhScan}) {
  const TABLE_HEAD = [
    { id: 'name', label: 'Reg.No', alignRight: false },
    { id: 'company', label: 'Full Name', alignRight: false },
    { id: 'role', label: 'Category', alignRight: false },
    { id: 'isVerified', label: 'Mobile', alignRight: false },
    { id: 'print', label: `${FwhScan ? 'Check/Checed In'   : 'Certificate Print'}     `, alignRight: false },
  ];
const halltypeforTable  = halltype.toLowerCase()
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
    dispatch(getCerSettings());
    dispatch(getfont());    
  },[]);
  const  {users,error,loading,cersettingsData,fontSize}  = useSelector((state) => ({ ...state.users}));
console.log('fontSize',fontSize)
 



  const [open, setOpen] = useState(false);
  const [openformmodal, setOpenFormModal] = useState(false);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [qrvalue, setQrValue] = useState('');

  const [attende, setAttende] = useState('');
  const [qrCode, setQrCode] = useState('');

  const componentRef = useRef(null);


useEffect(()=>{
      setPage(0);
      setFilterName(qrCodevalue);
},[qrCodevalue])



  const { top_px, right_px } = cersettingsData ? cersettingsData[0 ] : {top_px : '0px', right_px : '0px' }



/////////////////////////////////

///////////////////////////////

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};
 const callback = ()=>{
  setOpenFormModal(false)
 }
const handlePrint = () => {
  setOpen(false);
  const reg_num =  qrvalue
  dispatch(changeCertificatestatus({reg_num,halltype}))
  dispatch(updateUser({reg_num,halltype,}))
};

const handleFormOpen = () => {
  setOpenFormModal(true);
};
 




  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value );
  };

  


  const setQrvaluefunc = (id,name) =>{
    setQrValue(id)
    setAttende(name)
  };

  


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;
  

if(users.length){
  
  var filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);
  
function descendingComparator(a, b, orderBy) {
    
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) =>{
      return(
         _user.reg_num.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
         _user.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
         _user.mob_num.toLowerCase().indexOf(query.toLowerCase()) !== -1 
      )
    })
  }
  return stabilizedThis.map((el) => el[0]);
}




 
}

const styleDiv = {
  position: "absolute",
   top: top_px,
    right: right_px,
     transform: "rotate(90deg)",
     fontSize : `${fontSize}px`
}



  const isNotFound = !filteredUsers?.length && !!filterName;



  return (
    <>
      <Container>
        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} checked={checked} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users?.length}
                  numSelected={selected.length}
                />
                <TableBody>
                  { users?.length ?   filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,inx) => {
                    const { reg_num,full_name,category,mob_num,printed} = row;
                    const selectedUser = selected.indexOf(full_name) !== -1;

                    return (
                      <TableRow hover key={inx} tabIndex={-1}  selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} />
                        </TableCell>
                        <TableCell align="left">{reg_num}</TableCell>
                        <TableCell align="left">{full_name}</TableCell>
                        <TableCell align="left">{category == '1' ?  'Delegate' : category == '2' ? 'Faculty' : category == '3' ? 'Members' :  category == '4' ? 'Accompany person' : 'N/A'  }</TableCell>

                        <TableCell align="left">{mob_num}</TableCell>
                        <TableCell align="left"> 
       
                         <Button variant="contained" color={(row[halltypeforTable] === '0' && 'primary') || 'error'} onClick= {() => setQrvaluefunc(reg_num,full_name)}>
              {FwhScan ? row[halltypeforTable] === '0' ? 'Check' : 'Checked In' : row[halltypeforTable] === '0' ? 'print' : 'printed'}
            </Button >
            </TableCell>
                       {FwhScan ? <TableCell align="left"> 
       
                       <Button variant="contained" color={(row[halltypeforTable] === '0' && 'primary') || 'error'} onClick= {() => handleClickOpen()}>
                       Manul Scan
                       </Button >
            </TableCell> : null }
                      </TableRow>
                    );
                  }) : ''}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

<Container style={{marginTop:"50px",padding:"50px"}}>
  <Card style={{padding:"50px"}}>  
  <ReactToPrint
        trigger={() => <Button style={{margin:"auto",display:"flex"}} variant="contained"  > Print this out</Button >}
        content={() => componentRef.current}
        onAfterPrint={() => handleClickOpen()}
      />
    
      { qrvalue.length > 0 && 
      <>
<div  ref={componentRef} style={{ marginTop: "100px", position: "relative" }}>
  <h3 style={styleDiv}>{attende}</h3>
</div>
</>
    }

  </Card>
</Container>
      <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Have you Printed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handlePrint} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      </div>
      <div>
      <FormModal 
      openformmodal={openformmodal}
      callback={callback}
      />
      </div>
      <BackdropLoading loading={loading}/>
    </>
  );
}