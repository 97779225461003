import * as React from 'react';
import { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Dialog from '@mui/material/Dialog';
import { cersettings,updateFontSize } from 'src/redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { Container, Box, Card, CardHeader, Stack, TextField, Button, ToggleButtonGroup, ToggleButton } from '@mui/material';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const TabPanel3 = () => {

  ////////////////////chatgptcode////////////////////

  const [fontSize2, setFontSize] = React.useState( 16); // Set default font size
  const [alignment, setAlignment] = React.useState('down'); // Set default alignment (down)

  const handleSubmit2 = (event) => {
    event.preventDefault();
    setFontSize(event.target.font.value); // Update font size from text field
  };

  const handleAlignmentChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };



  //////////////////chatgptcode//////////////////////

  const { cersettingsData,fontSize } = useSelector((state) => ({ ...state.users }));

console.log('XfontSizeX',fontSize)

  const initialState = {
    right: '20',
    top: '20'
  }


  const dispatch = useDispatch()
  const [values, setValue] = useState(initialState);
  const [fonts, setFonts] = useState('');

  const { right, top } = values


  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setValue({ ...values, [name]: value });
  };



  useEffect(() => {


    const { top_px, right_px } = cersettingsData[0];


    const topValue = top_px.slice(0, -2); // Removes the last two characters ("px")
    const rightValue = right_px.slice(0, -2); // Removes the last two characters ("px")
    setValue({ top: topValue, right: rightValue })
    setFonts(fontSize)
  }, [])


  const onInputChange = (e) => {
   
    setFonts(e.target.value);
  };


  const handleChange = () => {

    dispatch(cersettings({ top, right, toast }))
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateFontSize({fonts,toast}));
     };


  

  return (
    <>
      <div>

    



    <Container>
        <Box sx={{ pt: 2 }}>
        <Card>
        <CardHeader title={'QRCode Size'}/>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} style={
          {padding:'20px'}
        }>
      
      <form  onSubmit={handleSubmit}>
                <TextField
                  type="text"
                  variant='outlined'
                  color='secondary'
                  label="Font Size"
                  onChange={onInputChange}
                  value={fonts}
                  name="font"
                  size="small"
                  fullWidth

                />


           <Stack style={{marginTop:'10px'}}>
           <Button variant="contained" color="secondary" type="submit">
              {'Update'}
              </Button>
           </Stack>
        </form>
      
        </Stack>
        </Card>
             </Box>
    </Container>
    <Container>
      <Box sx={{ pt: 2 }}>
        <Card>
          <CardHeader title={'Delegate Name Size and Alignment'} /> {/* Updated title */}
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} style={{ padding: '20px' }}>
            <form onSubmit={handleSubmit2}>
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Font Size"
                onChange={onInputChange} // Assuming onInputChange is defined elsewhere
                value={fontSize2}
                name="font"
                size="small"
                fullWidth
              />
              <Stack style={{ marginTop: '10px' }}>
                <Button variant="contained" color="secondary" type="submit">
                  {'Update'}
                </Button>
              </Stack>
            </form>

            <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignmentChange} aria-label="alignment">
              <ToggleButton value="down">Down</ToggleButton>
              <ToggleButton value="up">Up</ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          {/* Display QR code here, potentially using the fontSize state */}
          <div style={{ fontSize2: `${fontSize2}px`, textAlign: alignment }}>
            {/* Your QR code generation logic here */}
          </div>
        </Card>
      </Box>
    </Container>






        <div>
      
   
    </div>
      </div>
    </>

  )
}

export default TabPanel3